/* eslint-disable no-console */
import BugsnagPerformance from "@bugsnag/browser-performance";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { getCookie } from "cookies-next";
import LZString from "lz-string";

import { CookieKeys } from "../enums/CookieKeys.enum";

const PLATFORM = process.env.PLATFORM || "local";
const BUGSNAG_API_KEY = process.env.BUGSNAG_API_KEY || null;

if (!Bugsnag.isStarted() && BUGSNAG_API_KEY) {
  const cookieStore = getCookie(CookieKeys.UserData);
  Bugsnag.start({
    apiKey: BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    releaseStage: PLATFORM,
    enabledReleaseStages: ["prod", "staging"],
    onError(event) {
      try {
        const userData = cookieStore
          ? JSON.parse(LZString.decompressFromBase64(cookieStore) || "{}")
          : {};
        event.addMetadata("User Data", {
          ...userData,
          accessToken: "",
          refreshToken: "",
        });
      } catch (error) {
        console.error("Failed to parse user data:");
      }
    },
  });

  try {
    const userData = cookieStore
      ? JSON.parse(LZString.decompressFromBase64(cookieStore) || "{}")
      : {};
    if (userData?.id) {
      Bugsnag.setUser(
        userData.id.toString(),
        userData.email || "unknown@example.com",
        `${userData.first_name || "Unknown"} ${userData.last_name || "User"}`
      );
    }
  } catch (error) {
    Bugsnag.setUser();
  }

  BugsnagPerformance.start({ apiKey: BUGSNAG_API_KEY });
}

export { Bugsnag };
